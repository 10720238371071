import Image from "next/image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Heading from "../typography/heading";
import ImageModal from "../modal/image-modal";
import { Projects } from "@app/config/project";

const Project = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [project, setProject] = React.useState("");

  const handleToggle = () => {
    setModalShow(!modalShow);
  };

  const onClose = () => {
    setModalShow(false);
  };

  return (
    <>
      <Heading
        Head1="OUR"
        Head2="GALLERY"
        para="Explore our diverse portfolio of industrial automation solutions through our project gallery. From state-of-the-art automation systems to cutting-edge equipment installations, each image showcases our expertise and commitment to excellence. Witness the innovative solutions we've delivered to clients across industries, tailored to enhance efficiency, productivity, and sustainability.

Our gallery is a testament to our dedication to quality and precision in every project we undertake. Browse through to see how we bring ideas to life with the latest automation technologies and solutions.

"
      />
      <div className="contair">
        <Row className="R-pro">
          {Projects.length > 0 &&
            Projects.map((project, index) => (
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                xxl={4}
                className="C-pro"
                key={index}
              >
                {/* // */}
                <Image
                  src={project.imageUrl}
                  className="image img-fluid"
                  priority
                  alt={project.alt}
                  onClick={() => {
                    handleToggle();
                    setProject(project);
                  }}
                />
              </Col>
            ))}
        </Row>
      </div>
      <ImageModal
        show={modalShow}
        handleToggle={handleToggle}
        imageUrl={project}
        onClose={onClose}
      />
    </>
  );
};

export default Project;
