import Project01 from "@public/images/ias-images/image_56.webp";
import Project02 from "@public/images/P02.webp";
import Project04 from "@public/images/ias-images/image_50.webp";
import Project05 from "@public/images/ias-images/image_57.webp";
import Project06 from "@public/images/ias-images/image_25.webp";
import Project03 from "@public/images/P07.webp";

export const Projects = [
  {
    imageUrl: Project01,
    alt: "automation",
  },
  {
    imageUrl: Project02,
    alt: "industrial automation",
  },
  {
    imageUrl: Project03,
    alt: "automation solutions",
  },
  {
    imageUrl: Project04,
    alt: "automation services",
  },
  {
    imageUrl: Project05,
    alt: "factory automation",
  },
  {
    imageUrl: Project06,
    alt: "plc",
  },
];
