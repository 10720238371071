import Image from "next/image";

const { Modal, Button } = require("react-bootstrap");

const ImageModal = ({ imageUrl, handleToggle, show, onClose }) => {
  return (
    <>
      <Modal show={show} onHide={handleToggle} className="image-modal">
        <Button onClick={onClose} className="close">
          X
        </Button>
        <Image
          src={imageUrl.imageUrl}
          alt={imageUrl.alt}
          className="img-fluid"
        />
      </Modal>
    </>
  );
};

export default ImageModal;
